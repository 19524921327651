<template>
  <div class="menu-body" v-if="$oidc.isAuthenticated">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="main-con">
      <div class="mat-info-con">
        <div class="plant-dd">
          <MultiSelect :labelName="'Region'" :options="regions" @onConfirmClick="onCheck"></MultiSelect>
        </div>
      </div>
      <div class="mat-info-con multi-check-list">
        <div class="each-region-wise" v-for="(eachRegionWise, index) in regionWise" :key="index">
          <div style="font: 1.33rem 'BarlowM', sans-serif">
            {{ eachRegionWise.regionName }}
          </div>
          <ul class="plant-list">
            <li class="each-plant-li" v-for="(plant, index) in eachRegionWise.plantList" :key="index">
              <input type="checkbox" :id="index" :value="plant.plantId" v-model="plant.checked"
                style="width: 20px; height: 20px" />
              <label class="option-text" :for="index">{{
                plant.plantName
              }}</label>
            </li>
          </ul>
        </div>
        <div class="no-data" v-if="!regionWise || regionWise.length === 0">
          <img alt="no data" src="@/assets/No_Data.svg" />
          <span class="ed-date">Please modify dropdown</span>
        </div>
      </div>
      <div class="flex-button-div back-btn-con button-reg">
        <button @click="back" class="primary-color back-btn">Back</button>
        <b-button pill class="add-action-btn" @click="assignIt">
          Assign for Validation
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Api from "../../../common/constants/api";
import ApiCalls from "../../../common/api/apiCalls";
import Loader from "../../../components/common/Loader.vue";
import MultiSelect from "./MultiSelect.vue";
import roles from "@/common/constants/roles";
import helper from "@/common/helper";

export default {
  name: "AssignValidationRequest",
  data() {
    return {
      roles: roles,
      showLoader: false,
      region: null,
      reqIds: [],
      selected: [],
      regions: [],
      regionWise: [],
    };
  },
  components: {
    Breadcrumb,
    Loader,
    MultiSelect,
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 2,
      subMenu: 4,
    });
    this.$store.dispatch("breadcrumb", {
      title: "Assign Plant Validation ",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        {
          name: "Governance / ",
          redirect: "/governance",
          primary: true,
        },
        { name: "Assign Plant Validation ", primary: false },
      ],
    });
  },
  created() {
    if (!this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER)) {
      this.$router.push("/");
      return;
    }
    this.showLoader = true;
    this.getRegions();
    this.reqIds = helper.getAssignValidationReqIDs();
  },
  methods: {
    getRegions() {
      ApiCalls.httpCallDetails(Api.GETREGIONLIST, "get").then((data) => {
        this.regions = data.data;
        this.showLoader = false;
      });
    },
    getRegionWiseList() {
      let payload = [];
      this.selected.forEach((item) => {
        payload.push(item.regionId);
      });
      ApiCalls.httpCallDetails(
        Api.GETPLANTLISTACCORDINGREGION,
        "post",
        payload
      ).then((data) => {
        data.data.forEach((eachRegion) => {
          eachRegion.plantList.forEach((eachPlant) => {
            eachPlant.checked = false;
          });
        });
        this.regionWise = data.data;
        this.showLoader = false;
      });
    },
    assignIt() {
      let plantIds = [];
      this.regionWise.forEach((eachRegion) => {
        eachRegion.plantList.forEach((eachPlant) => {
          if (eachPlant.checked === true) plantIds.push(eachPlant.plantId);
        });
      });      
      let payload = { reqIds: this.reqIds, plantIds: plantIds };
      if (this.reqIds.length && plantIds.length) {
        this.showLoader = true;
        ApiCalls.httpCallDetails(
          Api.ASSIGNPLANTFORVALIDATION,
          "post",
          payload
        ).then((data) => {
          if (data.data) {
            this.showLoader = false;
            helper.setAssignValidationReqIDs([]);
            this.$router.push("/assign-plant-validation");
          }
        });
      }
    },
    back() {
      this.$router.push("/assign-plant-validation");
    },
    onCheck(val) {
      console.log(val);
      this.selected = val;
      this.showLoader = true;
      this.getRegionWiseList();
    },
  },
};
</script>
<style scoped>
.main-con {
  padding: 2rem;
}

.last-asmt-con {
  font-size: 1.083rem;
  font-family: "BarlowR", sans-serif;
}

.select-lable {
  z-index: 2;
}

.plant-dd {
  width: 30% !important;
}

.save-button {
  background-color: #eff3ff !important;
  font-size: 1.1666666666666667rem;
  font-family: "BarlowM", sans-serif;
  width: 5.6rem;
  height: 2.9rem;
  border-color: #869ed8;
  margin-left: 1rem;
}

.status-last-asmt-con {
  font-size: 1.083rem;
  font-family: "BarlowR", sans-serif;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
}

.mat-info-con {
  display: flex;
  align-items: center;
  padding: 1rem 0;
}

.multi-check-list {
  justify-content: space-between;
}

.plant-select {
  height: 54px;
  border-color: #313131;
}

.plant-card {
  background: #fffce3;
  width: 160px;
  height: 54px;
  margin-left: 1.5em;
  text-align: center;
  color: #414141;
  padding: 5px;
  border-radius: 7px;
}

.validation-btn {
  border-color: #274ba7;
}

.flex-button-div {
  display: flex;
  align-content: center;
  justify-content: end;
}

.back-btn {
  background: transparent;
  border: none;
}

.validation-btn:hover {
  color: #274ba7 !important;
  background: #274ba741 0% 0% no-repeat padding-box !important;
}

.add-action-btn {
  background: #274ba7;
  border-color: hsl(223, 62%, 40%);
  padding: 0.75rem 1.4rem;
  margin-left: 2rem;
}

.add-action-btn:hover {
  border-color: #274ba7 !important;
  color: #fff !important;
  background: #274ba7 0% 0% no-repeat padding-box !important;
}

.add-action-btn:active {
  border-color: #274ba7 !important;
  color: #fff !important;
  background: #274ba7 0% 0% no-repeat padding-box !important;
}

.add-action-btn:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.each-region-wise {
  font-size: 1.166rem !important;
  flex: 3;
  font-family: "BarlowR", sans-serif !important;
}

.option-text {
  margin: 0 1rem;
  font-size: 1.34rem !important;
  font-family: "BarlowM", sans-serif !important;
}

.plant-list {
  padding: 1rem 0;
  width: 100%;
  list-style: none;
}

.back-btn-con {
  /* padding-right: 2rem; */
  text-align: right;
}

.no-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 8rem;
  justify-content: space-evenly;
}

.last-asmt-date {
  font-family: "BarlowM", sans-serif;
}

.each-plant-li {
  display: flex;
  align-items: center;
}

.status-icons {
  margin: 0px 5px;
  width: 1.66rem;
  height: 1.66rem;
}

.status-name {
  margin-right: 1rem;
  font: 1.08rem "BarlowM", sans-serif;
  color: #313131;
}

@media only screen and (max-width: 767px) {
  .mat-info-con[data-v-dcabda2e] {
    display: block !important;
  }

  .plant-dd[data-v-dcabda2e] {
    width: 100% !important;
  }

  .plant-card[data-v-dcabda2e] {
    width: 100% !important;
    margin-left: 0em !important;
    margin-top: 1em;
  }
}
</style>