<template id="MultiSelect">
  <div class="flex-div">
    <div style="flex: 7">
      <label for="region" class="form-label select-lable lable-text">
        Region
      </label>
      <div class="dropdown" @click="showDropdown">
        <b-form-input class="form-control select-text select-text-pd cust-st-icon" placeholder="Select Regions"
          v-model="selectedText">
        </b-form-input>
      </div>
      <div class="multi-select" v-if="show">
        <ul>
          <li v-for="(option, index) in options" :key="index">
            <input type="checkbox" :id="index" :value="option" v-model="selected" @change="onCheck" />
            <label class="option-text" :for="index">{{ option.regionName }}</label>
          </li>
        </ul>
      </div>
    </div>
    <b-button pill variant="outline-secondary" class="save-button save-btn primary-color validation-btn"
      @click="onConfirmClick">
      Confirm
    </b-button>
  </div>
</template>
<script>
export default {
  name: "MultiSelect",
  props: { options: Array },
  data() {
    return {
      show: false,
      selected: [],
      selectedText: "",
    };
  },
  methods: {
    showDropdown() {
      this.show = !this.show;
    },
    onCheck() {
      this.selectedText = "";
      this.selected.forEach((element) => {
        this.selectedText += element.regionName + ", ";
      });
      this.selectedText = this.selectedText.substring(
        0,
        this.selectedText.length - 2
      );
    },
    onConfirmClick() {
      this.show = false;
      this.$emit("onConfirmClick", this.selected);
    },
  },
  //   watch: {
  //     selected(val) {
  //     },
  //   },
};
</script>

<style scoped>
.dropdown {
  position: relative;
  cursor: pointer;
}

.option-text {
  margin: 0 1rem;
  font-size: 1.34rem !important;
  font-family: "BarlowM", sans-serif !important;
}

.flex-div {
  display: flex;
  align-items: center;
  flex: 10;
}

.multi-select {
  position: relative;
}

.multi-select ul {
  border: 1px solid #ddd;
  box-shadow: 2px 1px 4px 0.1rem rgb(0 0 0 / 25%);
  border-top: 0;
  border-radius: 0 0 3px 3px;
  left: 0px;
  padding: 8px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  list-style: none;
  max-height: 150px;
  z-index: 2;
  background-color: #fff;
  overflow: auto;
}

.select-lable {
  z-index: 2;
}

.save-button {
  background-color: #eff3ff !important;
  font-size: 1.1666666666666667rem;
  font-family: "BarlowM", sans-serif;
  width: 7.5rem;
  height: 3rem;
  border-color: #869ed8;
  margin-left: 1rem;
}

.validation-btn {
  padding: 0 1rem;
}

.validation-btn:hover {
  color: #274ba7 !important;
  background: #274ba741 0% 0% no-repeat padding-box !important;
}
</style>